import React from "react"
import SEO from "../components/seo"

import { TextLeftImageRightBlock } from "../components/blocks/TextLeftImageRightBlock"
import { CenteredTextBlock } from "../components/blocks/CenteredTextBlock"
import BasicTemplate from "../components/BasicTemplate"

const AboutUsPage = () => {
  return (
    <BasicTemplate title="About Us">
      <span>
        <SEO
          title="About Us"
          description="We are open to the public! We are a public bar and event hall available for rent; use us for your next event today!"
        />
        <CenteredTextBlock
          title="Our Origins"
          large
          style={{ paddingBottom: 20 }}
        >
          The O’Fallon KC Hall is open to the public and is operated by The
          O’Fallon Columbus Club. The club is proud to present the many venues
          and services we offer to both our membership and the general public.
          Please use the navigation above to discover more about the main
          features of our venue, everyday operations and ongoing events.
          <br />
          <br />
          The Columbus Club is the "sister organization" to the O’Fallon Council
          of the Knights of Columbus. It is the incorporated entity responsible
          for the operation and maintenance of the facilities and services. The
          Columbus Club has a full-time staff of professional employees who are
          dedicated to making your event a success.
          <br />
          <br />
          The <strong>manager and event coordinator</strong> are available from
          initial booking to the completion of your event. This multi-purpose
          facility is located directly across from the O’Fallon Community Park
          on Highway 50 in O’Fallon, Illinois. There is ample parking available
          on site.
        </CenteredTextBlock>

        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/zWHUYA6.jpg"
          title="Main Hall"
          altText="Main Hall"
        >
          The Main Hall is the largest venue available for weddings, auctions,
          trivia nights and any other large events. Customers, including many
          charities have found a perfect fit with our low cost structure and
          have annually hosted their fund raising efforts at our facility.
        </TextLeftImageRightBlock>

        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/dwvHIUy.jpg"
          title="Lower Hall"
          altText="Lower Hall"
        >
          Smaller events can be held in the <strong>Lower Hall</strong> area for
          more intimate gatherings. Depending on availability, both the Main
          Hall and the Lower Hall can be rented for the same event.
        </TextLeftImageRightBlock>

        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/NnhvS7R.jpg"
          title="Amenities"
          altText="Amenities"
        >
          We are equipped with a <strong>full-scale kitchen and bar</strong>
          , and depending on the size of the event, we can cater in house or
          recommend one of our caterers on our preferred list.
          <br />
          <br />
          Additionally, the halls are equipped with wireless internet and flat
          screen TV’s for projecting presentations.
        </TextLeftImageRightBlock>
      </span>
    </BasicTemplate>
  )
}

export default AboutUsPage
